export default {
  translations: {
    walletBalance: "钱包余额",
    walletRewards: "奖励",
    manageMyWallet: "管理我的钱包",
    creditMyWallet: "充值我的钱包",
    offerPrepaidCard: "赠送礼品卡",
    usePrepaidCard: "兑换礼品卡",
    error: {
      walletNotFetched: "检索您的钱包时出错。请稍后再试！"
    }
  }
};
