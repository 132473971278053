export default {
  translations: {
    walletBalance: "Wallet Balance",
    walletRewards: "Wallet Rewards",
    manageMyWallet: "Manage my Wallet",
    creditMyWallet: "Credit my Wallet",
    offerPrepaidCard: "Offer a Gift Card",
    usePrepaidCard: "Activate a Gift Card",
    error: {
      walletNotFetched:
        "An error occurred while trying to retrieve your Wallet information. Please try again later."
    }
  }
};
