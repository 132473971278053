export default {
  translations: {
    walletBalance: "Solde du porte-monnaie",
    walletRewards: "Récompenses du porte-monnaie",
    manageMyWallet: "Gérer mon porte-monnaie",
    creditMyWallet: "Créditer mon porte-monnaie",
    offerPrepaidCard: "Offrir une carte cadeau",
    usePrepaidCard: "Activer une carte cadeau ",
    error: {
      walletNotFetched:
        "Une erreur s'est produite lors de la récupération des informations relatives à votre Porte-monnaie. Veuillez réessayer plus tard."
    }
  }
};
