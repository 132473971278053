export default {
  translations: {
    walletBalance: "Wallet saldo",
    walletRewards: "Wallet beloningen",
    manageMyWallet: "Beheer mijn Wallet",
    creditMyWallet: "Wallet opwaarderen",
    offerPrepaidCard: "Een cadeaubon aanbieden",
    usePrepaidCard: "Verzilver cadeaubon",
    error: {
      walletNotFetched:
        "Bij het ophalen van je Wallet-informatie is er een fout opgetreden. Probeer het later opnieuw."
    }
  }
};
