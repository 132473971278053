import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withTheme } from 'styled-components';
import Cookies from 'js-cookie';
import { Container } from '@mooncake/ui';
import { Wallet } from '@mooncake/ui/lib/icons';
import { MOONCAKE_DATA_UBICONNECT_FETCHED } from '@mooncake/mooncake-gateway-core/constants';
import { pollWindowVariable } from '@mooncake/mooncake-gateway-ecom/utils';
import { fetchRewardsAction } from '../../features/rewards/rewardsSlice';
import { fetchWalletAction, setHasWallet } from '../../features/wallet/walletSlice';

import { authenticatedWalletLink } from '../../features/wallet/walletUtils';
import { currencyFormatter } from '@mooncake/utils';
import Tooltip from '../Tooltip';

import {
  ContainerLink,
  createMobilePastille,
  CustomContainer,
  CustomInnerContainer,
  CustomIcon,
  BalanceAmount,
  injectCSSMobilePastille,
  BalanceText,
  WalletText,
} from './styles';
import { fetchEnd, fetchStart } from '../../core/features/app/appSlice';
import Dot from '../CustomIcons/Dot';
import { sendTrackingClickEvent } from '../../utils/tracking';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canShowPrepaid: !!(window.SitePreferences && window.SitePreferences.ENABLE_PREPAID_CARD),
      canShowRewards:
        !!(window.SitePreferences && window.SitePreferences.ENABLE_WALLET_REWARDS) && this.props.rewardsBalance > 0,
      tooltipActive: false,
      alreadySeen: JSON.parse(localStorage.getItem('alreadySeenRewards')),
      showPastille: false,
      linkToPrepaidProductPage: this.props.linkToPrepaidProductPage,
      linkToRedeemProductPage: this.props.linkToRedeemProductPage,
      isLoggedIn: false,
    };
    this.customContainer = React.createRef();
    this.containerClick = false;
    this.linkRef = React.createRef();
  }

  redirectToTopupPage = () => {
    const { linkToTopupPage } = this.props;
    window.location.href = linkToTopupPage;
  };

  confirmWallet = () => {
    const { setHasWallet } = this.props;
    setHasWallet(true);
    this.redirectToTopupPage();
  };

  unbindEvent = () => {
    document.querySelector('wallet-currency-confirmation-modal').removeEventListener('onConfirm', this.confirmWallet);
    document.querySelector('wallet-currency-confirmation-modal').removeEventListener('onCloseModal', this.unbindEvent);
  };

  startFetchingWallet = (info) => {
    const {
      country,
      fetchEnd,
      fetchRewardsAction,
      fetchWalletAction,
      rewardsToClaim,
      storeCurrency,
      locale,
      theme,
    } = this.props;

    document
      .getElementById('mooncake-data')
      .removeEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, this.startFetchingWallet);

    const userId = info.detail && info.detail.userId;
    this.setState({ isLoggedIn: !!userId });

    fetchWalletAction(info.detail)
      .then(() => {
        fetchEnd();
        const noWalletCookie = Cookies.get(`noWallet_${userId}`);
        if (!!(window.SitePreferences && window.SitePreferences.ENABLE_WALLET_REWARDS) && !noWalletCookie) {
          fetchRewardsAction(country, storeCurrency, locale).then(() => {
            if (rewardsToClaim.length) {
              this.createPastille();
            }
          });
          injectCSSMobilePastille(theme);
        }
      })
      .catch(() => {
        fetchEnd();
      });
  };

  componentDidMount = () => {
    const { fetchStart } = this.props;
    fetchStart();

    pollWindowVariable('Constants').then(() => {
      if (window.Constants.SITE_ID) {
        const countryCode = window.Constants.SITE_ID.split('_')[0];
        this.setState({
          linkToPrepaidProductPage: `/${countryCode}${this.props.linkToPrepaidProductPage}`,
          linkToPrepaidRedeemPage: `/${countryCode}${this.props.linkToPrepaidRedeemPage}`,
        });
      }
    });
    document
      .getElementById('mooncake-data')
      .addEventListener(MOONCAKE_DATA_UBICONNECT_FETCHED, this.startFetchingWallet);
  };

  createPastille = () => {
    const { rewardsToClaim } = this.props;
    const { alreadySeen } = this.state;

    const showPastille =
      !!rewardsToClaim.length &&
      !(Array.isArray(alreadySeen) && rewardsToClaim.every((id) => alreadySeen.includes(id)));
    this.setState({
      showPastille,
      alreadySeen: showPastille ? null : rewardsToClaim,
    });
    createMobilePastille(showPastille);
  };

  openConfirmationModal = (e) => {
    e.preventDefault();
    document.dispatchEvent(new Event('openCurrencyConfirmationModal'));
    document.querySelector('wallet-currency-confirmation-modal').addEventListener('onConfirm', this.confirmWallet);
    document.querySelector('wallet-currency-confirmation-modal').addEventListener('onCloseModal', this.unbindEvent);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.rewardsToClaim !== this.props.rewardsToClaim) {
      this.createPastille();
    }
    if (prevProps.rewardsBalance !== this.props.rewardsBalance) {
      this.setState({
        canShowRewards:
          !!(window.SitePreferences && window.SitePreferences.ENABLE_WALLET_REWARDS) && this.props.rewardsBalance > 0,
      });
    }
    if (prevState.alreadySeen !== this.state.alreadySeen) {
      if (Array.isArray(this.state.alreadySeen) && this.state.alreadySeen.length) {
        localStorage.setItem('alreadySeenRewards', JSON.stringify(this.state.alreadySeen));
      } else {
        localStorage.removeItem('alreadySeenRewards');
      }
    }
  };

  handleLoginLink = (event, link) => {
    event.preventDefault();
    authenticatedWalletLink(this.state.isLoggedIn, link);
  };

  focusIn = () => {
    this.setState({
      tooltipActive: true,
    });
    this.containerClick = false;
  };

  onClickHandler = () => {
    this.setState({
      tooltipActive: !this.state.tooltipActive,
    });
    this.containerClick = false;
    sendTrackingClickEvent('wallet');
  };

  focusOut = () => {
    // if the event is not from the children
    if (!this.containerClick) {
      this.setState({
        tooltipActive: false,
      });
    } else {
      // focus on a child so that blur event can be called if clicking outside of container
      const child = this.customContainer?.current.children[0]?.children[0];
      if (child) {
        child.focus();
      }
    }
    this.containerClick = false;
  };

  onKeyDown = (event) => {
    if (event.key === 'Escape' && this.state.tooltipActive) {
      this.linkRef.current.focus();
      this.setState({
        tooltipActive: false,
      });
    } else if (event.key === 'Enter') {
      this.setState({
        // Toggle
        tooltipActive: !this.state.tooltipActive,
      });
    }
  };

  render() {
    const { balance, primaryBalance, userCurrency, locale, storeCurrency, theme, t, loading } = this.props;
    let currency = userCurrency;

    if (userCurrency === null) {
      currency = storeCurrency;
    }

    const currentBalance = this.state.canShowRewards ? balance : primaryBalance;
    const formattedCurrency = currencyFormatter(currentBalance, locale, currency, {
      allowPriceRounding: true,
    });
    const hasNotification =
      window.SitePreferences && window.SitePreferences.ENABLE_WALLET_REWARDS && this.state.showPastille;

    return (
      !loading && (
        <CustomContainer
          ref={this.customContainer}
          onFocus={this.focusIn}
          onClick={this.onClickHandler}
          onBlur={this.focusOut}
          onKeyDown={this.onKeyDown}
          onMouseDown={() => (this.containerClick = true)}
          isActive={this.state.tooltipActive}
          className={this.props.isUPC ? "is-upc" : ""}
        >
          <CustomInnerContainer maxWidth={'100%'} position={'relative'}>
            <ContainerLink
              ref={this.linkRef}
              canShowRewards={this.state.canShowRewards}
              className={`wallet-header-balance__container${this.state.tooltipActive ? ' active' : ''} ${this.props.isUPC ? ' is-upc' : ''}`}
              onFocus={(e) => e.stopPropagation()}
              tabIndex={0}
            >
              <Container position={'relative'} marginRight={'8px'}>
                <CustomIcon
                  className={'wallet-header-balance__icon'}
                  icon={Wallet}
                  color={'currentColor'}
                  verticalAlign="middle"
                />
              </Container>
              <Container>
                <WalletText bold>{t('wallet')}</WalletText>
              </Container>
              <Container overflow={'hidden'} display={'flex'} flexGrow={'1'} alignItems={'center'}>
                <BalanceText
                  bold
                  color={theme.colors.primary.contrast}
                  marginRight={this.state.canShowRewards ? 'auto' : '10px'}
                  canShowRewards={this.state.canShowRewards}
                >
                  {t('walletBalance')}
                </BalanceText>
                <BalanceAmount
                  className={'wallet-header-balance__total-balance'}
                  color={{
                    default: 'currentColor',
                    tablet: theme.colors.primary.contrast,
                  }}
                  textDecoration={'none'}
                  title={formattedCurrency}
                  canShowRewards={this.state.canShowRewards}
                >
                  {formattedCurrency}
                </BalanceAmount>
              </Container>
              {hasNotification && (
                <Container marginLeft="0.5rem">
                  <Dot />
                </Container>
              )}
            </ContainerLink>
            <Tooltip
              active={this.state.tooltipActive}
              totalBalance={formattedCurrency}
              className={'wallet-header-balance__tooltip'}
              canShowRewards={this.state.canShowRewards}
              storeCurrency={storeCurrency}
              canShowPrepaid={this.state.canShowPrepaid}
              hasNotification={hasNotification}
              linkToPrepaidProductPage={this.state.linkToPrepaidProductPage}
              linkToPrepaidRedeemPage={this.state.linkToPrepaidRedeemPage}
              linkLogin={this.handleLoginLink.bind(this)}
            />
          </CustomInnerContainer>
        </CustomContainer>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.wallet.balance,
  primaryBalance: state.wallet.primaryBalance,
  rewardsBalance: state.wallet.rewardsBalance,
  initialized: state.app.initialized,
  loading: state.app.loading,
  hasWallet: state.wallet.hasWallet,
  rewardsToClaim: state.rewards.rewardsToClaim,
  userCurrency: state.wallet.currency,
});

const mapDispatchToProps = {
  fetchStart,
  fetchEnd,
  fetchRewardsAction,
  fetchWalletAction,
  setHasWallet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withTheme(Content)));
