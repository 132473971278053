import { Container, Icon, Text } from "@mooncake/ui";
import { currencyFormatter, getPageUrl } from "@mooncake/utils";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import {
  StyledTooltipContainer,
  StyledLink,
  StyledPriceContainer,
  StyledCurrencyContainer,
  StyledLabel
} from "./styles";
import {
  WalletTop,
  PrepaidGive,
  PrepaidUse,
  WalletRewardBadge,
  Settings
} from "@mooncake/ui/lib/icons";
import Dot from "../CustomIcons/Dot";
import { sendTrackingClickEvent } from "../../utils/tracking";

const Tooltip = ({
  active,
  canShowRewards,
  canShowPrepaid,
  hasNotification,
  className,
  currency,
  storeCurrency,
  linkToPrepaidProductPage,
  linkToPrepaidRedeemPage,
  linkLogin,
  locale,
  primaryBalance,
  rewardsBalance,
  totalBalance,
  t,
  theme
}) => {
  const tooltipLinks = [];

  const currentCurrency = currency || storeCurrency;
  const formattedRewards = currencyFormatter(
    rewardsBalance,
    locale,
    currentCurrency,
    {
      allowPriceRounding: true
    }
  );

  const formattedPrimaryBalance = currencyFormatter(
    primaryBalance,
    locale,
    currentCurrency
  );

  const manageWalletUrl = getPageUrl("Wallet-ManagementPage");
  const creditMyWalletUrl = getPageUrl("Wallet-TopupStart");

  tooltipLinks.push({
      icon: Settings,
      title: "manageMyWallet",
      action: event => {
        sendTrackingClickEvent("wallet : manage my wallet");
        linkLogin(event, manageWalletUrl);
      },
      url: manageWalletUrl
    },
    {
      icon: WalletTop,
      title: "creditMyWallet",
      action: event => {
        sendTrackingClickEvent("wallet : credit my wallet");
        linkLogin(event, creditMyWalletUrl);
      },
      url: creditMyWalletUrl
    });

  if (canShowPrepaid && linkToPrepaidProductPage && linkToPrepaidRedeemPage) {
    tooltipLinks.push({
        icon: PrepaidGive,
        title: "offerPrepaidCard",
        url: linkToPrepaidProductPage,
        action: () => sendTrackingClickEvent("wallet : offer a gift card")
      },
      {
        icon: PrepaidUse,
        title: "usePrepaidCard",
        url: linkToPrepaidRedeemPage,
        action: () => sendTrackingClickEvent("wallet : activate a gift card")
      });
  }

  return (
    <StyledTooltipContainer
      onClick={e => e.stopPropagation()}
      id={"wallet-header-balance-tooltip"}
      className={`${className}${active ? " active" : ""}`}
      width={{
        default: "100%",
        tablet: "max(268px, 100%)"
      }}
      color={theme.colors.text.primary}
      textAlign={"left"}
      borderRadius={{
        default: "0",
        tabletLarge: "8px"
      }}
      backgroundColor={{
        default: "transparent",
        tabletLarge: theme.colors.background.primary,
      }}
      boxShadow={{
        default: "none",
        tabletLarge: "0 -5px 20px rgba(0, 0, 0, 0.12)"
      }}
      padding={{
        default: "8px",
        tabletLarge: "0"
      }}
    >
      {(primaryBalance > 0 || canShowRewards) && (
        <StyledCurrencyContainer
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"stretch"}
          borderRadius={{
            default: "8px",
            tabletLarge: "8px 8px 0 0"
          }}
          padding={"13.5px 16px"}
          fontSize={"14px"}
          color={theme.colors.text.secondary}
        >
          {primaryBalance > 0 && <Container
            display={"flex"}
            justifyContent={"space-between"}
          >
            <StyledLabel title={t("walletBalance")}>
              {t("walletBalance")}
            </StyledLabel>
            <StyledPriceContainer>
              {formattedPrimaryBalance}
            </StyledPriceContainer>
          </Container>}

          {canShowRewards && <Container
            display={"flex"}
            justifyContent={"space-between"}
          >
            <StyledLabel title={t("walletRewards")}>
              {t("walletRewards")}
            </StyledLabel>
            <StyledPriceContainer>
              <Icon
                size={Icon.SIZE.sm}
                icon={WalletRewardBadge}
                verticalAlign={"middle"}
              />
              &nbsp;{formattedRewards}
            </StyledPriceContainer>
          </Container>}
        </StyledCurrencyContainer>
      )}
      {tooltipLinks.length > 0 && (
        <Container padding={{
          default: "0",
          tabletLarge: "0 8px"
        }}>
          {tooltipLinks.map(link => (
            <StyledLink
              key={link.title}
              href={link.url}
              onClick={link.action}
              tabIndex={0}
            >
              <Icon
                size={Icon.SIZE.lg}
                icon={link.icon}
                color={"currentColor"}
              />
              <Text.Span marginLeft={"16px"}>{t(link.title)}</Text.Span>
              {link.title === "manageMyWallet" && hasNotification && (
                <Container marginLeft="0.5rem">
                  <Dot />
                </Container>
              )}
            </StyledLink>
          ))}
        </Container>
      )}
    </StyledTooltipContainer>
  );
};

const mapStateToProps = state => ({
  primaryBalance: state.wallet.primaryBalance,
  rewardsBalance: state.wallet.rewardsBalance,
  currency: state.wallet.currency,
  locale: state.app.locale
});

export default connect(mapStateToProps)(withTranslation()(withTheme(Tooltip)));
