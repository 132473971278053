export default {
  translations: {
    walletBalance: "Wallet-Guthaben",
    walletRewards: "Wallet-Belohnungen",
    manageMyWallet: "Mein Wallet verwalten",
    creditMyWallet: "Mein Guthaben auffüllen",
    offerPrepaidCard: "Eine Geschenkkarte anbieten",
    usePrepaidCard: "Eine Geschenkkarte einlösen",
    error: {
      walletNotFetched:
        "Beim Abholen deiner Wallet-Informationen ist ein Fehler aufgetreten. Versuche es später erneut."
    }
  }
};
